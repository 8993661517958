import expertiseImg1 from "~/assets/images/pages/homepage/assistance-technico-commerciale.png?url";
import expertiseImg4 from "~/assets/images/pages/homepage/bureau-ingenierie.png?url";
import expertiseImg3 from "~/assets/images/pages/homepage/distribution-et-logistique.png?url";
import hydrexImg from "~/assets/images/pages/homepage/reviews/hydrex.jpg?url";
import impactEnergieImg from "~/assets/images/pages/homepage/reviews/impact-energie.jpg?url";
import objectifEnergiesImg from "~/assets/images/pages/homepage/reviews/objectif-energies.jpg?url";
import solengieImg from "~/assets/images/pages/homepage/reviews/solengie.jpg?url";
import travauxElecImg from "~/assets/images/pages/homepage/reviews/travaux-elec.jpg?url";
import vosgesCharpentesImg from "~/assets/images/pages/homepage/reviews/vosges-charpentes.jpg?url";
import expertiseImg2 from "~/assets/images/pages/homepage/stock-permanent-adapte.png?url";

export const reviewsData = [
  {
    brandImageUrl: impactEnergieImg,
    title: "David GIRARD - Société IMPACT ÉNERGIE",
    subtitle: "Spécialiste du génie climatique",
    text: `Bientôt 10 ans que POwR Connect (anciennement KDI) m’accompagne dans mes projets photovoltaïques destinés principalement aux particuliers mais aussi sur des petits projets professionnels jusqu’à 36 kw.
<br/>Toujours de très bons conseils grâce à leurs équipes en région, pour ma part sur Montpellier. D’ailleurs dédicace spéciale au personnel de l’antenne de Mudaison qui est toujours au top !
<br/>Leurs expertises nous permettent d’anticiper les différents problèmes liés à notre activité, aux approvisionnements, aux disponibilités des produits, formation, …
<br/>Depuis la mise en place de leur Webshop, un gap important a été franchi en termes de rapidité, disponibilité, réactivité et fluidité pour mon activité. Je l’utilise, maintenant, en priorité.
<br/>Dans un contexte économique mondial complexe et très concurrentiel, ils savent tirer leur épingle du jeu, en grande partie, je pense, par l’état d’esprit qui règne au sein du personnel de l’entreprise, jeune, qualifié, dynamique et bienveillant.
<br/>Merci à toutes les équipes de POwR Connect !`,
  },
  {
    brandImageUrl: travauxElecImg,
    title: "HUE Romuald - Société TRAVAUX ELEC",
    subtitle: "Installateur",
    text: `POwR Connect est notre partenaire de confiance pour les solutions solaires photovoltaïques. Leur service client est exceptionnel, toujours réactif et prêt à répondre à nos besoins. La variété et la qualité des produits disponibles sur leur plateforme sont incomparables.
<br/>Nous apprécions particulièrement leurs conseils experts et leur connaissance approfondie du marché photovoltaïque. Ils nous tiennent informés des dernières innovations et produits, ce qui nous permet de rester compétitifs et à la pointe de la technologie.
<br/>Leur rôle de grossiste va au-delà de la simple fourniture de produits. POwR Connect nous aide à naviguer dans un marché complexe avec une efficacité remarquable, nous permettant ainsi de concentrer nos efforts sur la satisfaction de nos propres clients.
<br/>En résumé, POwR Connect est un atout précieux pour notre entreprise, et nous les recommandons vivement à toute organisation à la recherche de solutions solaires fiables et innovantes.`,
  },
  {
    brandImageUrl: hydrexImg,
    title: "Sylvain BOISROBERT - Co-Gérant de la Société HYDREX PHOTOVOLTAÏQUE",
    subtitle: "Spécialiste du génie climatique",
    text: `Dans le cadre de nos projets Particuliers et Industriels, POwR Connect nous accompagne dans le but de satisfaire nos clients.
<br/>Je tiens à remercier votre équipe pour votre réactivité dans l’établissement des chiffrages de nos projets qui représente un facteur clé de succès de la concrétisation des projets de nos clients.
<br/>Mention spéciale à Frédéric et Anthony pour leur disponibilité et conseils techniques ! Poursuivez ainsi !`,
  },
  {
    brandImageUrl: solengieImg,
    title: "Jean-François LOISEAU - Société SOLENGIE",
    subtitle: "Installateur",
    text: `POwR Connect est mon fournisseur privilégié de solutions solaires photovoltaïques. J'apprécie tout particulièrement leur réactivité, leur sens du service et le choix qu'ils proposent.
<br/>Je suis souvent preneur de leurs conseils avisés, eux qui possèdent une vision extra large du marché PV mondial.
<br/>Ils restent en alerte permanente sur les nouveaux produits et nous font partager leur expérience et expertise.
<br/>Dans une activité à forte inertie, il est important que le grossiste joue un rôle tampon efficient, ce qu'arrivent à très bien faire les équipes POwR Connect.`,
  },
  {
    brandImageUrl: vosgesCharpentesImg,
    title: "Manon Olivo - Société VOSGES CHARPENTES",
    subtitle: "Installateur",
    text: `Je suis très satisfaite du service qu'offre POwR Connect et plus particulièrement d'avoir des interlocuteurs dédiés qui connaissent notre manière de fonctionner et les produits que l'on pose régulièrement.
<br/>Nous sommes au lancement de notre activité mais nous nous sentons soutenus en travaillant avec l'équipe POwR Connect par les différents gestes commerciaux qui peuvent être faits et les modifications plannings auxquelles nous devons régulièrement faire face.`,
  },
  {
    brandImageUrl: objectifEnergiesImg,
    title: "Matthieu NICOLE - Société OBJECTIF ENERGIES",
    subtitle: "Installateur",
    text: `POwR Connect, c'est plus qu'un simple distributeur, un partenaire ! Des produits tops, de la dispo, un back-office. Un partenaire qui nous aide au quotidien !!`,
  },
];

export const expertiseData = [
  { image: expertiseImg1, label: "Un bureau d'études pv" },
  { image: expertiseImg2, label: "Un stock permanent" },
  { image: expertiseImg3, label: "Une logistique optimisée" },
  { image: expertiseImg4, label: "Un sourcing performant" },
];
